import { jsx as _jsx } from "react/jsx-runtime";
import { useActions, useGlobalState } from "piral-core";
import { toast } from "react-toastify";
import { ToastPending, ToastSuccess, ToastError } from "../toastify/ToastView";
export function createCustomApi() {
    return function (context) {
        var _useGlobalComponents = function (name) {
            var nameFn = useGlobalState(function (m) { return m.components[name]; });
            return nameFn;
        };
        var _useGlobalState = function (name) {
            var nameFn = useGlobalState(function (m) { return m[name]; });
            return nameFn;
        };
        var _useActions = function () {
            var nameFn = useActions();
            return nameFn;
        };
        var _toastCreate = function (_a) {
            var customId = _a.customId, info = _a.info;
            return toast(_jsx(ToastPending, { info: info }), {
                toastId: customId,
                isLoading: true,
                closeButton: false
            });
        };
        var _toastUpdate = function (_a) {
            var customId = _a.customId, type = _a.type, info = _a.info, hashLink = _a.hashLink, error = _a.error;
            var domView = type === "success" ?
                _jsx(ToastSuccess, { info: info, hashLink: hashLink })
                : _jsx(ToastError, { info: info, error: error });
            return toast.update(customId, {
                render: domView,
                isLoading: false,
                closeButton: true,
                type: type,
                autoClose: 8000
            });
        };
        var _toastPromise = function (_a) {
            var event = _a.event, info = _a.info, hashLink = _a.hashLink, error = _a.error;
            return toast.promise(event, {
                pending: _jsx(ToastPending, { info: info }),
                success: _jsx(ToastSuccess, { info: info, hashLink: hashLink }),
                error: _jsx(ToastError, { info: info, error: error })
            });
        };
        var _toastView = function (_a) {
            var info = _a.info, type = _a.type;
            return toast(info, {
                type: type
            });
        };
        return function (api, meta) { return ({
            _useGlobalComponents: _useGlobalComponents,
            _useGlobalState: _useGlobalState,
            _useActions: _useActions,
            _toastView: _toastView,
            _toastCreate: _toastCreate,
            _toastUpdate: _toastUpdate,
            _toastPromise: _toastPromise
        }); };
    };
}
