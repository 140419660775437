import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { Link } from "react-router-dom";
import { ExtensionSlot, } from "piral";
// 错误发生时呈现的组件
export var errors = {
    menu: function () { return _jsx("span", {}); },
    extension: function () { return _jsx("div", {}); },
    loading: function () { return (_jsx("div", { className: "pi-center", children: _jsxs("div", { className: "pi-error", children: [_jsx("div", { className: "pi-title", children: "Something Went Wrong" }), _jsx("div", { className: "pi-description", children: "An error occured during the loading process. Try refreshing or come back later." })] }) })); },
    not_found: function () { return (_jsxs("div", { children: [_jsx("p", { className: "error", children: "Could not find the requested page. Are you sure it exists?" }), _jsxs("p", { children: ["Go back ", _jsx(Link, { to: "/", children: "to the dashboard" }), "."] })] })); },
    feed: function (_a) {
        var error = _a.error;
        return (_jsxs("div", { className: "pi-error", children: [_jsx("div", { className: "pi-title", children: "Data Unavailable" }), _jsx("div", { className: "pi-description", children: "The demanded data has not been found. Please contact support to resolve this issue." }), _jsx("div", { className: "pi-details", children: error })] }));
    },
    page: function () { return (_jsxs("div", { className: "pi-error", children: [_jsx("div", { className: "pi-title", children: "Page Crashed" }), _jsx("div", { className: "pi-description", children: "Sorry for the inconvenience. We try to resolve the issue as soon as possible." })] })); },
    unknown: function () { return (_jsxs("div", { className: "pi-error", children: [_jsx("div", { className: "pi-title", children: "Unknown Error" }), _jsx("div", { className: "pi-description", children: "An unknown error occured." })] })); },
};
// 使用插槽（ExtensionSlot）从shell中解耦layout
export var error2 = {
    not_found: function (props) { return _jsx(ExtensionSlot, { name: "xxxx", params: props }); },
};
export var errors3 = {
    not_found: function (props) { return _jsx(ExtensionSlot, { name: "layout-error-not-found-container", params: props }); },
};
