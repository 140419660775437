var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import * as React from 'react';
import { ExternalLink } from '../LinkDom';
import { useChainId } from 'wagmi';
var ConfirmOrLoadingWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width:540px;\n  padding-top: 64px;\n  padding-bottom: 64px;\n  background: #1C1C20;\nborder-radius: 8px;\n.content{\n    display: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\n}\n\n  .text {\n    font-family: Montserrat-Light;\n    font-weight: 300;\n    font-size: 16px;\n    color: #A2A2A2;\n    text-align: center;\n\n  }\n  .bscText {\n    display: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\na{\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n}\n  }\n \n"], ["\n  width:540px;\n  padding-top: 64px;\n  padding-bottom: 64px;\n  background: #1C1C20;\nborder-radius: 8px;\n.content{\n    display: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\n}\n\n  .text {\n    font-family: Montserrat-Light;\n    font-weight: 300;\n    font-size: 16px;\n    color: #A2A2A2;\n    text-align: center;\n\n  }\n  .bscText {\n    display: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\na{\n    display: flex;\n    align-items: center;\n    font-size: 16px;\n}\n  }\n \n"])));
export function LoadingView(_a) {
    var children = _a.children, onDismiss = _a.onDismiss;
    return (_jsx(ConfirmOrLoadingWrapper, { children: _jsxs("div", { className: "content", children: [children, _jsx("div", { className: "text", children: "Please confirm this transaction in your wallet." })] }) }));
}
var WaitText = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-family: Montserrat-SemiBold;\nfont-weight: 600;\nfont-size: 20px;\ncolor: #FFFFFF;\nline-height: 24px;\nmargin: 32px 0 16px;\n"], ["\n  font-family: Montserrat-SemiBold;\nfont-weight: 600;\nfont-size: 20px;\ncolor: #FFFFFF;\nline-height: 24px;\nmargin: 32px 0 16px;\n"])));
var JumpIcon = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\ndisplay: inline-block;\n    width: 16px;\n    height: 16px;\n    background: url('/image/model/jump.png');\n    background-size: 100% 100%;\n    margin-left: 8px;\n"], ["\ndisplay: inline-block;\n    width: 16px;\n    height: 16px;\n    background: url('/image/model/jump.png');\n    background-size: 100% 100%;\n    margin-left: 8px;\n"])));
var ChainInfo = {
    97: {
        scanName: 'BscScan',
        scanLink: 'testnet.bscscan.com',
        iconName: 'ethicon',
        priceType: 'BNB',
        chainName: 'BNB Chain',
    },
    56: {
        scanName: 'BscScan',
        scanLink: 'bscscan.com',
        iconName: 'bscicon',
        priceType: 'BNB',
        chainName: 'BNB Chain',
    },
};
function getEtherscanLink(chainId, data, type) {
    var prefix = "https://".concat(ChainInfo[chainId].scanLink ||
        ChainInfo[56].scanLink);
    switch (type) {
        case 'transaction': {
            return "".concat(prefix, "/tx/").concat(data);
        }
        case 'token': {
            return "".concat(prefix, "/token/").concat(data);
        }
        case 'block': {
            return "".concat(prefix, "/block/").concat(data);
        }
        case 'address':
        default: {
            return "".concat(prefix, "/address/").concat(data);
        }
    }
}
export function SubmittedView(_a) {
    var children = _a.children, onDismiss = _a.onDismiss, hash = _a.hash, hideLink = _a.hideLink, isError = _a.isError;
    var chainId = useChainId();
    return (_jsx(ConfirmOrLoadingWrapper, { children: _jsxs("div", { className: "bscText", children: [children, _jsx(WaitText, { children: "Transaction submitted" }), !hideLink && !isError && chainId && hash && (_jsxs(ExternalLink, { href: getEtherscanLink(chainId, hash, 'transaction'), children: ["View on ", ChainInfo[chainId].scanName, " ", _jsx(JumpIcon, {})] }))] }) }));
}
var templateObject_1, templateObject_2, templateObject_3;
