var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback } from "react";
import { styled } from "styled-components";
var StyledHeaderLink = styled.a(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: left;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  color: #51E0CF;\n  font-size: 14px;\n  width: fit-content;\n  font-weight: 400;\n  padding: 10px 0 27px;\n  white-space: nowrap;\n  transition: 0.5s;\n\n"], ["\n  align-items: left;\n  outline: none;\n  cursor: pointer;\n  text-decoration: none;\n  color: #51E0CF;\n  font-size: 14px;\n  width: fit-content;\n  font-weight: 400;\n  padding: 10px 0 27px;\n  white-space: nowrap;\n  transition: 0.5s;\n\n"])));
var StyledLink = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-decoration: underline;\n  cursor: pointer;\n  color: ", ";\n  font-weight: 400;\n\n  :hover {\n    text-decoration: none;\n  }\n\n  :focus {\n    outline: none;\n    text-decoration: none;\n  }\n\n  :active {\n    text-decoration: none;\n  }\n"], ["\n  text-decoration: underline;\n  cursor: pointer;\n  color: ", ";\n  font-weight: 400;\n\n  :hover {\n    text-decoration: none;\n  }\n\n  :focus {\n    outline: none;\n    text-decoration: none;\n  }\n\n  :active {\n    text-decoration: none;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.text1;
});
export function ExternalHeaderLink(_a) {
    var _b = _a.target, target = _b === void 0 ? '_blank' : _b, href = _a.href, _c = _a.rel, rel = _c === void 0 ? 'noopener noreferrer' : _c, rest = __rest(_a, ["target", "href", "rel"]);
    var handleClick = useCallback(function (event) {
    }, [href, target]);
    return _jsx(StyledHeaderLink, __assign({ target: target, rel: rel, href: href, onClick: handleClick }, rest));
}
/**
 * Outbound link that handles firing google analytics events
 */
export function ExternalLink(_a) {
    var _b = _a.target, target = _b === void 0 ? '_blank' : _b, href = _a.href, _c = _a.rel, rel = _c === void 0 ? 'noopener noreferrer' : _c, rest = __rest(_a, ["target", "href", "rel"]);
    var handleClick = useCallback(function (event) {
        // if (target === '_blank' || event.ctrlKey || event.metaKey) {
        // } else {
        //   event.preventDefault()
        // }
    }, [href, target]);
    return _jsx(StyledLink, __assign({ target: target, rel: rel, href: href, onClick: handleClick }, rest));
}
var templateObject_1, templateObject_2;
