import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { createInstance, Piral } from "piral-core";
import { errors } from "./components/Errors";
import config from "./config";
import { createMenuApi } from 'piral-menu';
import { createFeedsApi } from 'piral-feeds';
import { createFormsApi } from 'piral-forms';
import { createDashboardApi } from 'piral-dashboard';
import { createNotificationsApi } from 'piral-notifications';
import { createContainersApi } from 'piral-containers';
import { createSearchApi } from 'piral-search';
import ThemeProvider, { ThemedGlobalStyle } from "./components/GlobalStyle";
import { createReduxApi } from "piral-redux";
import { createCustomApi } from "./components/customApi/creatCustomGlobalApi";
import { setAccount, setSignation } from "./components/actions/actions";
import RainbowKitConfig from "./components/walletManager/rainbowKitConfig";
import { createFetchApi } from "piral-fetch";
import { createPageLayoutsApi } from "piral-page-layouts";
import AppLayout from "./components/AppLayout";
import WalletListenerComponent from "./components/walletManager/eventHandlers";
import { walletConfig } from "./components/walletManager/walletConfig";
import Loading from "./components/Loading";
// change to your feed URL here (either using feed.piral.cloud or your own service)
// const feedUrl = "http://localhost:9009/triathonlab/api/v1/pilet";
var feedUrl = config.FEED_URL;
var timeout = 20000; // 20秒超时
var controller = new AbortController();
var signal = controller.signal;
var timeoutId = setTimeout(function () {
    controller.abort();
}, timeout);
export var instance = createInstance({
    state: {
        components: {
            "WalletListenerComponent": WalletListenerComponent,
            "walletConfig": walletConfig,
            LoadingIndicator: Loading,
        },
        errorComponents: errors,
    },
    actions: {
        setAccount: setAccount,
        setSignation: setSignation
    },
    LoadingIndicator: _jsx(Loading, {}),
    plugins: [
        createMenuApi(),
        createNotificationsApi(),
        createContainersApi(),
        createDashboardApi(),
        createFeedsApi(),
        createFormsApi(),
        createSearchApi(),
        createReduxApi(),
        createCustomApi(),
        createFetchApi(),
        createPageLayoutsApi({
            "layouts": {
                "LayoutTop": AppLayout
            },
            "fallback": 'LayoutTop',
        })
    ],
    requestPilets: function () {
        return fetch(feedUrl, { signal: signal })
            .then(function (res) {
            clearTimeout(timeoutId); // 请求成功，清除超时定时器
            return res.json();
        })
            .then(function (res) { return res.items; })
            .catch(function (error) {
            if (error.name === "AbortError") {
                // 请求超时
                console.log("request time out");
            }
            else {
                // 处理其他错误
                console.log("request error: ".concat(error));
            }
        });
    },
});
var root = createRoot(document.querySelector("#app"));
root.render(_jsxs(ThemeProvider, { children: [_jsx(ThemedGlobalStyle, {}), _jsx(RainbowKitConfig, { children: _jsx(Piral, { instance: instance }) })] }));
// 写法二
{ /* <Piral instance={instance}>
<SetComponent name="LoadingIndicator" component={Loader} />
<SetComponent name="Layout" component={Layout} />
<SetRoute path="/" component={Dashboard} />
<SetRoute path="/sitemap" component={Sitemap} />
</Piral> */
}
