var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import styled from 'styled-components';
import { LoadingView, SubmittedView } from './modelView';
import Model from '../Model';
import PeddingImg from '../../assets/image/model/pedding.png';
import ErrImg from '../../assets/image/model/cancle.png';
import submitImg from '../../assets/image/model/submit.png';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 540px;\nbackground: #1C1C20;\nborder-radius: 8px;\npadding: 64px 0;\ndisplay: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\n \n"], ["\n  width: 540px;\nbackground: #1C1C20;\nborder-radius: 8px;\npadding: 64px 0;\ndisplay: flex;\nflex-direction: column;\njustify-content: center;\nalign-items: center;\n \n"])));
var Section = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding: 24px;\n"], ["\n  padding: 24px;\n"])));
var BottomSection = styled(Section)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n  padding: 0 3rem 2rem;\n"], ["\n  border-bottom-left-radius: 20px;\n  border-bottom-right-radius: 20px;\n  padding: 0 3rem 2rem;\n"])));
var CloseBtn = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 146px;\n  height: 40px;\n  border-radius: 8px;\n  border: 1px solid #353945;\n  font-size: 14px;\n  font-family: Poppins-SemiBold, Poppins;\n  font-weight: 600;\n  color: #ffffff;\n  text-align: center;\n  line-height: 40px;\n  margin: 32px auto 0;\n  cursor: pointer;\n  &:hover {\n    border: none;\n    background: #fff;\n    color: #000;\n  }\n"], ["\n  width: 146px;\n  height: 40px;\n  border-radius: 8px;\n  border: 1px solid #353945;\n  font-size: 14px;\n  font-family: Poppins-SemiBold, Poppins;\n  font-weight: 600;\n  color: #ffffff;\n  text-align: center;\n  line-height: 40px;\n  margin: 32px auto 0;\n  cursor: pointer;\n  &:hover {\n    border: none;\n    background: #fff;\n    color: #000;\n  }\n"])));
var TitleText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 32px;\n  font-size: 24px;\n  font-family: Poppins-SemiBold, Poppins;\n  font-weight: 600;\n  color: #ffffff;\n  line-height: 32px;\n  text-align: center;\n"], ["\n  height: 32px;\n  font-size: 24px;\n  font-family: Poppins-SemiBold, Poppins;\n  font-weight: 600;\n  color: #ffffff;\n  line-height: 32px;\n  text-align: center;\n"])));
var WaitText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-family: Montserrat-SemiBold;\nfont-weight: 600;\nfont-size: 20px;\ncolor: #FFFFFF;\nline-height: 24px;\nmargin: 32px 0 16px;\n"], ["\n  font-family: Montserrat-SemiBold;\nfont-weight: 600;\nfont-size: 20px;\ncolor: #FFFFFF;\nline-height: 24px;\nmargin: 32px 0 16px;\n"])));
var IconLogoPend = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"], ["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"])), PeddingImg);
var IconLogoErr = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"], ["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"])), ErrImg);
var IconLogoSubmit = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"], ["\n  width: 70px;\n  height: 70px;\n  background: url(", ");\n  background-size: 100% 100%;\n  margin: 0 auto;\n"])), submitImg);
function ConfirmationPendingContent(_a) {
    var onDismiss = _a.onDismiss, _b = _a.pendingText, pendingText = _b === void 0 ? '' : _b;
    return (_jsx(_Fragment, { children: _jsxs(LoadingView, { onDismiss: onDismiss, children: [_jsx(IconLogoPend, { className: 'pedding' }), _jsx(WaitText, { children: "Waiting for confirmation" })] }) }));
}
function TransactionSubmittedContent(_a) {
    var onDismiss = _a.onDismiss, chainId = _a.chainId, hash = _a.hash;
    return (_jsx(_Fragment, { children: _jsx(SubmittedView, { onDismiss: onDismiss, hash: hash, children: _jsx(IconLogoSubmit, { className: 'submit' }) }) }));
}
var ShowText = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n    width: 340px;\n    font-family:Montserrat-Light;\n    font-weight: 300;\n    font-size: 16px;\n    color: #A2A2A2;\n    line-height: 24px;\n    text-align: center;\n    word-wrap: break-word;\n\n\n\n"], ["\n    width: 340px;\n    font-family:Montserrat-Light;\n    font-weight: 300;\n    font-size: 16px;\n    color: #A2A2A2;\n    line-height: 24px;\n    text-align: center;\n    word-wrap: break-word;\n\n\n\n"])));
export function TransactionErrorContent(_a) {
    var message = _a.message, onDismiss = _a.onDismiss, title = _a.title;
    return (_jsxs(Wrapper, { children: [_jsx(IconLogoErr, { className: 'cancle' }), _jsx(WaitText, { children: "Transaction canceled" }), _jsx(ShowText, { children: "Sorry, your transaction can not be completed for some reasons. This may be caused by you canceling the transaction, the transaction amount exceeding the limit or other potential issues." })] }));
}
export function WallectStatusModel(_a) {
    // if (!chainId) return null
    var isOpen = _a.isOpen, onDismiss = _a.onDismiss, attemptingTxn = _a.attemptingTxn, hash = _a.hash, pendingText = _a.pendingText, content = _a.content, error = _a.error, errorMsg = _a.errorMsg;
    // confirmation screen
    return (_jsx(Model, { isOpen: isOpen, onDismiss: onDismiss, children: attemptingTxn ? (_jsx(ConfirmationPendingContent, { onDismiss: onDismiss, pendingText: pendingText || '' })) : error ? (_jsx(TransactionErrorContent, { message: errorMsg !== null && errorMsg !== void 0 ? errorMsg : 'Failed!  Please try again.', onDismiss: onDismiss })) : hash ? (_jsx(_Fragment, { children: _jsx(TransactionSubmittedContent, { chainId: 97, hash: hash, onDismiss: onDismiss }) })) : (content && content()) }));
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
