var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import styled from 'styled-components';
import loadImg from '../assets/image/load/x.gif';
var Loading = function () { return (_jsxs(LoadIngContainer, { className: "loading-wrapper", children: [_jsxs("div", { className: "loading", children: [_jsxs("div", { className: "bulletouter", children: [_jsx("div", { className: "bulletinner" }), _jsx("div", { className: "mask" }), _jsx("div", { className: "dot" })] }), _jsx("p", { children: "loading!" })] }), _jsx("img", { src: loadImg, alt: "" })] })); };
export default Loading;
var LoadIngContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100vh;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  >img{\n    width: 80rem;\n    height: 80rem;\n  }\n"], ["\n  width: 100%;\n  height: 100vh;\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  >img{\n    width: 80rem;\n    height: 80rem;\n  }\n"])));
var templateObject_1;
