var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import styled from "styled-components";
var InfoView = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: fit-content;\n  height: fit-content;\n  @media (max-width: 1023px) {\n    height: fit-content;\n  }\n"], ["\n  width: fit-content;\n  height: fit-content;\n  @media (max-width: 1023px) {\n    height: fit-content;\n  }\n"])));
var InfoStatus = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 360rem;\n  height: fit-content;\n  font-size: 20rem;\n  @media (max-width:1023px){\n    width: 280rem;\n    height: fit-content;\n    font-size: 16rem;\n  }\n"], ["\n  width: 360rem;\n  height: fit-content;\n  font-size: 20rem;\n  @media (max-width:1023px){\n    width: 280rem;\n    height: fit-content;\n    font-size: 16rem;\n  }\n"])));
var InfoEvent = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  @media (max-width:1023px){\n    width: 280rem;\n    height: fit-content;\n    font-size: 12rem;\n  }\n"], ["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  @media (max-width:1023px){\n    width: 280rem;\n    height: fit-content;\n    font-size: 12rem;\n  }\n"])));
var InfoError = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  color: #d72c2c;\n  @media (max-width:1023px){\n    font-size: 14rem;\n  }\n"], ["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  color: #d72c2c;\n  @media (max-width:1023px){\n    font-size: 14rem;\n  }\n"])));
var HashHref = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  color: #507cd5;\n  margin-left: 15rem;\n  @media (max-width:1023px){\n    font-size: 14rem;\n    margin-left: 14rem;\n  }\n"], ["\n  width: fit-content;\n  height: fit-content;\n  font-size: 16rem;\n  color: #507cd5;\n  margin-left: 15rem;\n  @media (max-width:1023px){\n    font-size: 14rem;\n    margin-left: 14rem;\n  }\n"])));
var ToastPending = function (_a) {
    var info = _a.info;
    return (_jsxs(InfoView, { children: [_jsx(InfoStatus, { children: "Pending for transaction confirmation..." }), _jsx(InfoEvent, { children: info })] }));
};
var ToastSuccess = function (_a) {
    var info = _a.info, hashLink = _a.hashLink;
    return (_jsxs(InfoView, { children: [_jsx(InfoStatus, { children: "Transaction successful \uD83C\uDF89" }), _jsxs(InfoEvent, { children: [info, _jsx(HashHref, { href: '' + hashLink, children: "View on BscScan" })] })] }));
};
var ToastError = function (_a) {
    var info = _a.info, error = _a.error;
    return (_jsxs(InfoView, { children: [_jsxs(InfoStatus, { children: ["Transaction failed: ", error] }), _jsx(InfoEvent, { children: info })] }));
};
export { ToastPending, ToastSuccess, ToastError };
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
