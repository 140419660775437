var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { createGlobalStyle, css, ThemeProvider as StyledComponentsThemeProvider, } from "styled-components";
import '../../assets/font/index.css';
import React, { useMemo } from "react";
export var ThemedGlobalStyle = createGlobalStyle(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    html {\n        font-size: calc( 100vw / 1920 );\n        width: 100vw;\n        min-height: 100vh;\n        scroll-behavior: smooth;\n        background: #000011;\n        ", ";\n    }\n    * {\n        margin: 0;\n        padding: 0;\n        box-sizing: border-box;\n    }\n    a {\n        text-decoration: none;\n    }\n    ul,\n    li {\n        list-style: none;\n    }\n    body {\n        scroll-behavior: smooth;\n    }\n  "], ["\n    html {\n        font-size: calc( 100vw / 1920 );\n        width: 100vw;\n        min-height: 100vh;\n        scroll-behavior: smooth;\n        background: #000011;\n        ", ";\n    }\n    * {\n        margin: 0;\n        padding: 0;\n        box-sizing: border-box;\n    }\n    a {\n        text-decoration: none;\n    }\n    ul,\n    li {\n        list-style: none;\n    }\n    body {\n        scroll-behavior: smooth;\n    }\n  "])), function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            font-size: calc( 100vw / 375 );\n        "], ["\n            font-size: calc( 100vw / 375 );\n        "])));
});
export var MEDIA_WIDTHS = {
    upToExtraSmall: 500,
    upToSmall: 720,
    upToMedium: 960,
    upToLarge: 1280
};
var mediaWidthTemplates = Object.keys(MEDIA_WIDTHS).reduce(function (accumulator, size) {
    ;
    accumulator[size] = function (a, b, c) { return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        @media (max-width: ", "px) {\n          ", "\n        }\n      "], ["\n        @media (max-width: ", "px) {\n          ", "\n        }\n      "])), MEDIA_WIDTHS[size], css(a, b, c)); };
    return accumulator;
}, {});
export function theme() {
    return {
        // media queries
        mediaWidth: mediaWidthTemplates,
        //rwd
        mobile: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        display: none;\n        ", "\n      "], ["\n        display: none;\n        ", "\n      "])), mediaWidthTemplates.upToSmall(templateObject_4 || (templateObject_4 = __makeTemplateObject(["display:inherit;"], ["display:inherit;"])))),
        desktop: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        ", "\n      "], ["\n        ", "\n      "])), mediaWidthTemplates.upToSmall(templateObject_6 || (templateObject_6 = __makeTemplateObject(["display:none;"], ["display:none;"])))),
        mobileHeaderHeight: '54px',
    };
}
export default function ThemeProvider(_a) {
    var children = _a.children;
    var themeObject = useMemo(function () { return theme(); }, []);
    return _jsx(StyledComponentsThemeProvider, { theme: themeObject, children: children });
}
export var PcShowContainter = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n", ";\n"], ["\n", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
});
export var MobileShowContainer = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\ndisplay: none;\n", ";\n"], ["\ndisplay: none;\n", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: block;\n"], ["\n  display: block;\n"])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
