var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useEffect } from 'react';
import { disconnect, signMessage, verifyMessage, getBalance, readContract, simulateContract, writeContract, sendTransaction, waitForTransactionReceipt, multicall, getWalletClient } from '@wagmi/core';
import { walletConfig } from './walletConfig';
import { useConnectModal, useAccountModal, useChainModal, } from '@rainbow-me/rainbowkit';
import { parseEther } from "viem";
import { useAccount } from "wagmi";
import { instance } from "../../index";
import { watchAsset } from "viem/actions";
export var WalletListenerComponent = function () {
    var app = instance.root;
    var openConnectModal = useConnectModal().openConnectModal;
    var openAccountModal = useAccountModal().openAccountModal;
    var openChainModal = useChainModal().openChainModal;
    var _useActions = app._useActions;
    var _a = _useActions(), setAccount = _a.setAccount, setSignation = _a.setSignation;
    var account = useAccount();
    var supportedChainIds = walletConfig.chains.map(function (item) {
        return item.id;
    });
    useEffect(function () {
        if (account && !account.isConnected) {
            setAccount(null);
            setSignation(null);
        }
        else {
            setAccount(account);
            setSignation(null);
        }
    }, [account.address, account.chainId]);
    var checkConnect = function (acc) {
        var supportedChainIds = walletConfig.chains.map(function (item) {
            return item.id;
        });
        if (!acc.isConnected) {
            if (openConnectModal) {
                openConnectModal();
            }
            return false;
        }
        else if (acc.chainId && !supportedChainIds.includes(acc.chainId)) {
            if (openChainModal) {
                openChainModal();
            }
            return false;
        }
        return true;
    };
    useEffect(function () {
        var handleConnect = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!checkConnect(account))
                    return [2 /*return*/];
                try {
                    if (openConnectModal) {
                        openConnectModal();
                    }
                }
                catch (error) {
                    app.emit('WalletPilet.ConnectedEmit', {
                        state: 'error',
                        error: error.shortMessage
                    });
                }
                return [2 /*return*/];
            });
        }); };
        var handleSwitchChain = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    if (openChainModal) {
                        openChainModal();
                    }
                }
                catch (error) {
                    app.emit('WalletPilet.ChangeChainEmit', {
                        state: 'error',
                        error: error.shortMessage
                    });
                }
                return [2 /*return*/];
            });
        }); };
        var handleDisconnect = function () { return __awaiter(void 0, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, disconnect(walletConfig)];
                    case 1:
                        _a.sent();
                        app.emit('WalletPilet.DisconnectedEmit', {
                            state: 'success',
                            isDisconnected: true
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        app.emit('WalletPilet.DisconnectedEmit', {
                            state: 'error',
                            error: error_1.shortMessage
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var handleAddToken = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var walletClient, result, error_2;
            var address = _b.address, decimals = _b.decimals, symbol = _b.symbol;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!checkConnect(account))
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, getWalletClient(walletConfig)];
                    case 2:
                        walletClient = _c.sent();
                        return [4 /*yield*/, watchAsset(walletClient, {
                                type: 'ERC20',
                                options: {
                                    address: address,
                                    decimals: decimals,
                                    symbol: symbol,
                                },
                            })];
                    case 3:
                        result = _c.sent();
                        app.emit('WalletPilet.AddTokenEmit', {
                            state: 'success',
                            result: result
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        error_2 = _c.sent();
                        app.emit('WalletPilet.AddTokenEmit', {
                            state: 'error',
                            error: error_2.shortMessage,
                        });
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        var handleSignMsg = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var signResult, error_3;
            var msg = _b.msg;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!checkConnect(account))
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, signMessage(walletConfig, {
                                message: msg,
                            })];
                    case 2:
                        signResult = _c.sent();
                        setSignation(signResult);
                        app.emit('WalletPilet.SignedMsg', {
                            state: 'success',
                            result: signResult
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _c.sent();
                        app.emit('WalletPilet.SignedMsg', {
                            state: 'error',
                            error: error_3.shortMessage
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var handleVerifyMessage = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var result, error_4;
            var address = _b.address, message = _b.message, signature = _b.signature;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, verifyMessage(walletConfig, {
                                address: address,
                                message: message,
                                signature: signature
                            })];
                    case 1:
                        result = _c.sent();
                        app.emit('WalletPilet.VerifyMessageEmit', {
                            state: 'success',
                            result: result
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_4 = _c.sent();
                        app.emit('WalletPilet.VerifyMessageEmit', {
                            state: 'error',
                            error: error_4.shortMessage,
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var handleGetBalance = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var balance, error_5;
            var address = _b.address, token = _b.token, eventKey = _b.eventKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, getBalance(walletConfig, {
                                address: address,
                                token: token ? token : '',
                            })];
                    case 1:
                        balance = _c.sent();
                        app.emit('WalletPilet.GetBalanceEmit', {
                            state: 'success',
                            result: balance,
                            eventKey: eventKey
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _c.sent();
                        app.emit('WalletPilet.GetBalanceEmit', {
                            state: 'error',
                            error: error_5.shortMessage,
                            eventKey: eventKey
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var handleReadContract = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var result, error_6;
            var abi = _b.abi, address = _b.address, fn = _b.fn, args = _b.args, eventKey = _b.eventKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!checkConnect(account))
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, readContract(walletConfig, {
                                abi: abi,
                                address: address,
                                functionName: fn,
                                args: args,
                            })];
                    case 2:
                        result = _c.sent();
                        app.emit('WalletPilet.ReadedEmit', {
                            result: result,
                            eventKey: eventKey
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        error_6 = _c.sent();
                        app.emit('WalletPilet.ReadedEmit', {
                            state: 'error',
                            eventKey: eventKey,
                            error: error_6.shortMessage
                        });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        var handleWriteContract = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var request, hash, error_7;
            var abi = _b.abi, address = _b.address, fn = _b.fn, args = _b.args, eventKey = _b.eventKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!checkConnect(account))
                            return [2 /*return*/];
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, simulateContract(walletConfig, {
                                abi: abi,
                                address: address,
                                functionName: fn,
                                args: args
                            })];
                    case 2:
                        request = (_c.sent()).request;
                        return [4 /*yield*/, writeContract(walletConfig, request)];
                    case 3:
                        hash = _c.sent();
                        app.emit('WalletPilet.WritedEmit', {
                            state: 'pending',
                            eventKey: eventKey,
                            hash: hash,
                            error: null
                        });
                        return [4 /*yield*/, waitForTransactionReceipt(walletConfig, {
                                hash: hash
                            })];
                    case 4:
                        _c.sent();
                        app.emit('WalletPilet.WritedEmit', {
                            state: 'success',
                            eventKey: eventKey,
                            hash: hash,
                            error: null
                        });
                        return [3 /*break*/, 6];
                    case 5:
                        error_7 = _c.sent();
                        app.emit('WalletPilet.WritedEmit', {
                            state: 'error',
                            eventKey: eventKey,
                            hash: null,
                            error: error_7.shortMessage
                        });
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }); };
        var handleSend = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var hash, res, error_8;
            var to = _b.to, value = _b.value, eventKey = _b.eventKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, sendTransaction(walletConfig, {
                                to: to,
                                value: parseEther(value.toString())
                            })];
                    case 1:
                        hash = _c.sent();
                        app.emit('WalletPilet.SendedEmit', {
                            state: 'pending',
                            eventKey: eventKey,
                            hash: hash,
                            error: null
                        });
                        res = waitForTransactionReceipt(walletConfig, {
                            hash: hash,
                        });
                        app.emit('WalletPilet.SendedEmit', {
                            state: 'success',
                            eventKey: eventKey,
                            hash: hash,
                            error: null
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _c.sent();
                        app.emit('WalletPilet.SendedEmit', {
                            state: 'error',
                            eventKey: eventKey,
                            hash: hash,
                            error: error_8.shortMessage
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var handleMultiCall = function (_a) { return __awaiter(void 0, [_a], void 0, function (_b) {
            var result, error_9;
            var contracts = _b.contracts, eventKey = _b.eventKey;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, multicall(walletConfig, {
                                contracts: contracts,
                            })];
                    case 1:
                        result = _c.sent();
                        app.emit('WalletPilet.MultiCallEmit', {
                            state: 'success',
                            eventKey: eventKey,
                            result: result,
                            error: null
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _c.sent();
                        app.emit('WalletPilet.MultiCallEmit', {
                            state: 'error',
                            eventKey: eventKey,
                            error: error_9.shortMessage
                        });
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        app.on('WalletPilet.Connect', handleConnect);
        app.on('WalletPilet.SwitchChain', handleSwitchChain);
        app.on('WalletPilet.Disconnect', handleDisconnect);
        app.on('WalletPilet.AddToken', handleAddToken);
        app.on('WalletPilet.SignMsg', handleSignMsg);
        app.on('WalletPilet.VerifyMessage', handleVerifyMessage);
        app.on('WalletPilet.GetBalance', handleGetBalance);
        app.on('WalletPilet.ReadContract', handleReadContract);
        app.on('WalletPilet.WriteContract', handleWriteContract);
        app.on('WalletPilet.Send', handleSend);
        app.on('WalletPilet.MultiCall', handleMultiCall);
        return function () {
            app.off('WalletPilet.Connect', handleConnect);
            app.off('WalletPilet.SwitchChain', handleSwitchChain);
            app.off('WalletPilet.Disconnect', handleDisconnect);
            app.off('WalletPilet.AddToken', handleAddToken);
            app.off('WalletPilet.SignMsg', handleSignMsg);
            app.off('WalletPilet.VerifyMessage', handleVerifyMessage);
            app.off('WalletPilet.GetBalance', handleGetBalance);
            app.off('WalletPilet.ReadContract', handleReadContract);
            app.off('WalletPilet.WriteContract', handleWriteContract);
            app.off('WalletPilet.Send', handleSend);
            app.off('WalletPilet.MultiCall', handleMultiCall);
        };
    }, [app, account, openConnectModal, openAccountModal, openChainModal]);
    return null;
};
export default WalletListenerComponent;
