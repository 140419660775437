var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { metaMaskWallet, okxWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { createConfig } from "wagmi";
import { defineChain } from 'viem';
import { connectorsForWallets } from "@rainbow-me/rainbowkit";
import { http } from "wagmi";
import { mainnet, bsc, polygon, sepolia, bscTestnet, polygonAmoy } from "viem/chains";
import ETHIcon from "../../assets/image/chainIcons/eth@2x.png";
import BSCIcon from "../../assets/image/chainIcons/BNB@2x.png";
import PolygonIcon from "../../assets/image/chainIcons/Polygon@2x.png";
import NetXIcon from "../../assets/image/chainIcons/NetX@2x.png";
import ETHScanIcon from "../../assets/image/chainIcons/EtherScan@2x.png";
import BSCScanIcon from "../../assets/image/chainIcons/BscScan@2x.png";
import PolygonScanIcon from "../../assets/image/chainIcons/PolygonScan@2x.png";
import NetXScanIcon from "../../assets/image/chainIcons/NetXScan@2x.png";
// main network
export var ETHMain = defineChain(__assign(__assign({}, mainnet), { icon: ETHIcon, scanLogo: ETHScanIcon }));
export var BSCMain = defineChain(__assign(__assign({}, bsc), { icon: BSCIcon, scanLogo: BSCScanIcon }));
export var PolygonMain = defineChain(__assign(__assign({}, polygon), { icon: PolygonIcon, scanLogo: PolygonScanIcon }));
export var TSCMain = defineChain({
    id: 345,
    name: 'TSC Mainnet',
    icon: NetXIcon,
    scanLogo: NetXScanIcon,
    iconUrl: NetXIcon,
    nativeCurrency: {
        decimals: 18,
        name: 'TAS',
        symbol: 'TAS',
    },
    rpcUrls: {
        default: { http: ['https://rpc01.trias.one'] },
    },
    blockExplorers: {
        default: {
            name: 'TSCScan',
            url: 'https://www.tscscan.io'
        },
    }
});
// test network
export var ETHTestSepolia = defineChain(__assign(__assign({}, sepolia), { icon: ETHIcon, scanLogo: ETHScanIcon }));
export var BSCTest = defineChain(__assign(__assign({}, bscTestnet), { icon: BSCIcon, scanLogo: BSCScanIcon }));
export var PolygonTestAmoy = defineChain(__assign(__assign({}, polygonAmoy), { icon: PolygonIcon, iconUrl: PolygonIcon, scanLogo: PolygonScanIcon }));
export var TSCTest = defineChain({
    id: 820522,
    name: 'TSC Testnet',
    icon: NetXIcon,
    scanLogo: NetXScanIcon,
    iconUrl: NetXIcon,
    nativeCurrency: {
        decimals: 18,
        name: 'tTAS',
        symbol: 'tTAS',
    },
    rpcUrls: {
        default: { http: ['https://testnet.tscscan.io/testrpc'] },
    },
    blockExplorers: {
        default: {
            name: 'TSCScan',
            url: 'https://testnet.tscscan.io'
        },
    }
});
export var TSCPre = TSCTest;
// chainlist
var MainNetWork = [
    TSCMain, BSCMain, ETHMain, PolygonMain
];
var TestNetWork = [
    TSCTest, BSCTest, ETHTestSepolia, PolygonTestAmoy
];
var connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: [metaMaskWallet, okxWallet, walletConnectWallet],
    },
], {
    appName: 'NetX',
    projectId: 'edf45e86a100cf6c894c3845ac9a2e3f',
});
var activeChain = process.env.APP_ENV_NAME === 'prod' ? MainNetWork : process.env.APP_ENV_NAME === 'pre' ? TSCPre : TestNetWork;
export var walletConfig = createConfig({
    connectors: connectors,
    chains: activeChain,
    transports: (_a = {},
        _a[activeChain[0].id] = http(),
        _a[activeChain[1].id] = http(),
        _a[activeChain[2].id] = http(),
        _a[activeChain[3].id] = http(),
        _a),
    ssr: true,
});
