import { jsx as _jsx } from "react/jsx-runtime";
import { lightTheme, RainbowKitProvider, } from '@rainbow-me/rainbowkit';
import { QueryClientProvider, QueryClient, } from '@tanstack/react-query';
import * as React from 'react';
import { WagmiConfig } from 'wagmi';
import { walletConfig } from "./walletConfig";
var queryClient = new QueryClient();
export default function RainbowKitConfig(_a) {
    var children = _a.children;
    return (_jsx(WagmiConfig, { config: walletConfig, children: _jsx(QueryClientProvider, { client: queryClient, children: _jsx(RainbowKitProvider, { locale: 'en', theme: lightTheme({
                    accentColor: '#2E6AFF',
                    accentColorForeground: '#FFFFFF',
                    borderRadius: 'small',
                    fontStack: 'system',
                    overlayBlur: 'small',
                }), children: children }) }) }));
}
;
