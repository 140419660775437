var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { ExtensionSlot } from "piral-core";
import Footer from "./Footer";
import Header from "./Header";
import styled from 'styled-components';
import { WallectStatusModel } from "./WallectStatusModel";
import { useEffect, useState } from "react";
import { instance } from "..";
import { Toastify } from "./toastify/toastify";
import { useHistory, useLocation } from "react-router-dom";
var AppLayout = function (_a) {
    var children = _a.children;
    var history = useHistory();
    var location = useLocation();
    useEffect(function () {
        instance.on('Shell.OpenInfoModelEmit', function (params) {
            if (params) {
                var status = params.status;
                switch (status) {
                    case 0:
                        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: true, hash: '', attemptingTxn: true, error: false, errorMsg: '' }));
                        break;
                    case 1:
                        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: true, hash: '88888888888', attemptingTxn: false, error: false, errorMsg: '' }));
                        break;
                    default:
                        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: true, hash: '', attemptingTxn: false, error: true, errorMsg: '' }));
                        break;
                }
            }
        });
        instance.on('Shell.ChangeInfoModelEmit', function (params) {
            console.log('Shell.ChangeInfoModelEmit==>', params);
            if (params) {
                var status = params.status, hash = params.hash, chainId = params.chainId;
                switch (status) {
                    case 1:
                        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: true, hash: hash, attemptingTxn: false }));
                        break;
                    default:
                        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: true, attemptingTxn: false, error: true }));
                        break;
                }
            }
        });
        return function () {
        };
    }, [instance]);
    var _b = useState({
        transactionModalOpen: false,
        hash: '',
        attemptingTxn: false,
        error: false,
        errorMsg: ''
    }), walletStatusParams = _b[0], setwalletStatusParams = _b[1];
    var transactionOnDismiss = function () {
        setwalletStatusParams(__assign(__assign({}, walletStatusParams), { transactionModalOpen: false, hash: '', attemptingTxn: false, error: false, errorMsg: '' }));
        instance.emit('Shell.CloseInfoModelEmit', {});
    };
    useEffect(function () {
        window.scrollTo(0, 0);
        return function () {
        };
    }, [location, history]);
    return _jsxs(Container, { children: [_jsx(NavHeader, { className: "Header", children: _jsx(Header, {}) }), _jsxs(PlietsContainer, { children: [(location.pathname === '/') && _jsx(ExtensionSlot, { name: "Home" }), children] }), _jsx(WallectStatusModel, { isOpen: walletStatusParams.transactionModalOpen, onDismiss: transactionOnDismiss, hash: walletStatusParams.hash, attemptingTxn: walletStatusParams.attemptingTxn, error: walletStatusParams.error, errorMsg: walletStatusParams.errorMsg }), _jsx(Toastify, {}), _jsx(Footer, {})] });
};
export default AppLayout;
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100vw;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  height: auto;\n"], ["\n  width: 100vw;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n  height: auto;\n"])));
var NavHeader = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: fixed;\n  z-index: 66;\n  width: 100vw;\n\n"], ["\n  position: fixed;\n  z-index: 66;\n  width: 100vw;\n\n"])));
var PlietsContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  /* flex: 1; */\n  min-height: calc(100vh - 80rem);\n  ", ";\n"], ["\n  /* flex: 1; */\n  min-height: calc(100vh - 80rem);\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.mediaWidth.upToSmall(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n   padding-top: 54rem;\n   min-height: calc(100vh - 500rem);\n  "], ["\n   padding-top: 54rem;\n   min-height: calc(100vh - 500rem);\n  "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
